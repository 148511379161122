import { NpmClubCardBenefitsPage } from '@plurix/ecom-pages'
import { Redirect } from '@reach/router'
import { graphql } from 'gatsby'
import React from 'react'

import type { ClubCardBenefitsPageSectionData } from '@plurix/ecom-pages/src/types/ClubCard/ClubCardBenefits'
import type { PageProps } from 'gatsby'

interface ClubCardBenefitsPageSection {
  cmsClubCardBenefitsLp?: {
    sections?: [{ data?: ClubCardBenefitsPageSectionData }]
  }
}

export default function ClubCardBenefitsPage({
  data,
  location: { pathname },
}: PageProps<ClubCardBenefitsPageSection>) {
  const clubCardBenefitsPageData =
    data?.cmsClubCardBenefitsLp?.sections?.[0]?.data

  if (!clubCardBenefitsPageData || !clubCardBenefitsPageData.visibility) {
    const params = new URLSearchParams({ from: encodeURIComponent(pathname) })

    return <Redirect to={`/404?${params.toString()}`} noThrow />
  }

  return <NpmClubCardBenefitsPage data={clubCardBenefitsPageData} />
}

export const querySSG = graphql`
  query ClubCardBenefitsPageQuery {
    cmsClubCardBenefitsLp {
      sections {
        data
      }
    }
  }
`
