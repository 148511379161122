import { graphql, useStaticQuery } from 'gatsby'

import type {
  BannerWithLink,
  MenuBannerWithLink,
} from '@plurix/ecom-pages/src/types/CMS'

const useCmsFullCategoriesMenu = () => {
  const {
    cmsFullCategoriesMenu: { sections },
  } = useStaticQuery(
    graphql`
      query FullCategories {
        cmsFullCategoriesMenu {
          sections {
            name
            data
          }
        }
      }
    `
  )

  const allCategories = sections?.find(
    (item: { name: string }) => item.name === 'Menu'
  )?.data?.departament

  const departments: Array<MenuBannerWithLink> | undefined = sections
    ?.filter((item: { name: string }) => item.name === 'department')
    ?.map((item: { data: BannerWithLink }) => item.data)

  return { allCategories, departments }
}

export default useCmsFullCategoriesMenu
